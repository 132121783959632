.header{
    background-image:linear-gradient(rgba(19,72,151,0.6), rgba(19,72,151,0.6)), url("../public/assets/wolfson.jpeg") ;
    /* url('../public/assets/idfImg.jpeg') */
    height: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* opacity: 64%; */
}
.bg{
    min-height: 100vh;
    background-color: #134897;
}
.divTitle{
    height: inherit;
    display: flex;
    align-items: flex-end;
    /* align-items: center; */
    justify-content: center;
    color: white;
    font-size: 40px;
    font-weight: bold;
}
.title{
    padding-bottom: 25px;
    width: 300px;
    text-align: center;
   
}
.subTitle{
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 25px;
    font-size: 20px;
    font-weight: bold;
}
.miniTitle{
    font-size: 35px;
    font-weight: bold;
    padding-bottom: 10px;
    color:#2E8BFF
}
.buttons{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}
.buttonDiv{
    padding-top: 40px;
    display: flex;
    justify-content: center;
    direction: rtl;
}
.button{
    background-color: #2E8BFF;
    color: white;
    font-size: 20px;
    font-weight: bold;
    height: 68px;
    width: 143px;
    border: none;
    border-radius: 20px 0px 20px 0px;
    cursor: pointer;
}
.button:active{
    opacity: 0.5;
}
.quantity{
    background-color: white;
    height: 68px;
    width: 143px;
    color: #2E8BFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0px 20px 0px;
    font-size: 20px;
    font-weight: bold;
}
